import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { linearBackground } from '~/shared/utils/styled';
import { TextBlockForContentCardsLayout } from './TextBlockForContentCards';
import { breakpoints } from '~/theme';

export const StyledTextBlockForContentCards = styled.div<{
    position: TextBlockForContentCardsLayout;
    backgroundColor?: string;
}>(
    ({ theme }) => ({
        whiteSpace: 'pre-line',
        padding: `${theme.spaces[3]} ${theme.spaces[4]}`,

        [breakpoints.md]: {
            padding: `${theme.spaces[4]} ${theme.spaces[4]}`,
        },
    }),
    switchProp('position', {
        on: ({ backgroundColor }) => ({
            ...linearBackground(backgroundColor),
            inset: 'auto 0 0 0',
        }),
        below: ({ theme, backgroundColor }) => ({
            backgroundColor,
            [breakpoints.lg]: {
                minHeight: theme.sizes.contentCardMinHeight,
            },
        }),
    })
);

export const StyledTexts = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[1],
}));

export const StyledTextsWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spaces[3],
    width: '100%',
    overflow: 'hidden',
}));

export const StyledArrowButtonWrapper = styled.div<{ hideOnMobile?: boolean }>(
    ({ hideOnMobile }) => ({
        display: hideOnMobile ? 'none' : 'block',

        [breakpoints.md]: {
            display: 'block',
        },
    })
);
